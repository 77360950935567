import React, { useState, useEffect, useRef } from 'react';
import format from 'date-fns/format';

import { client as apollo } from 'cccisd-apollo';
import Table from 'cccisd-graphql-table';
import Modal from 'cccisd-modal';
import Loader from 'cccisd-loader';

import IconClipboard from 'cccisd-icons/copy';
import IconAdd from 'cccisd-icons/plus';

import CommunitySurveyPlayer from './CommunitySurveyPlayer';
import sitesQuery from './siteList.graphql';
import responsesQuery from './responses.graphql';
import setClipboardText from './setClipboardText';

const Boilerplate = window.cccisd.boilerplate;
export const DEPLOYMENT_HANDLE = 'coalitions_community_surveys';

function getColumns() {
    return [
        {
            name: 'pawn.pawn.pawnId',
            label: 'Respondent ID',
            filter: true,
            sort: true,
        },
        {
            name: 'completedAt',
            label: 'Completed',
            filter: true,
            filterSettings: {
                type: 'date',
            },
            sort: true,
            render: ({ value }) => format(value, 'MM/DD/YYYY'),
        },
        {
            name: 'devTags.CCS_Zip',
            label: 'Zip Code',
            filter: true,
            sort: true,
        },
        {
            name: 'devTags.CCS_ChildrenInHome',
            label: 'Children In Household',
            filter: true,
            sort: true,
        },
        {
            name: 'devTags.CCS_Gender',
            label: 'Gender',
            filter: true,
            sort: true,
        },
        {
            name: 'raceEthnList',
            label: 'Race/Ethnicity',
            filter: false,
            sort: false,
            hideInCsv: true,
            render: ({ row }) => {
                const raceEthnOptions = [
                    row['devTags.CCS_RaceEthn_AfricanAmerican'] === 'Yes' && 'African American',
                    row['devTags.CCS_RaceEthn_AmericanIndianAlaskaNative'] === 'Yes' &&
                        'American Indian or Alaska Native',
                    row['devTags.CCS_RaceEthn_Asian'] === 'Yes' && 'Asian',
                    row['devTags.CCS_RaceEthn_HispanicLatinx'] === 'Yes' && 'Hispanic or Latinx',
                    row['devTags.CCS_RaceEthn_White'] === 'Yes' && 'White',
                    row['devTags.CCS_RaceEthn_NotListed'] === 'Yes' && 'Other/Not Listed',
                ];
                return <>{raceEthnOptions.filter(v => v).join(', ')}</>;
            },
        },
        {
            name: 'devTags.CCS_RaceEthn_AfricanAmerican',
            label: 'Race/Ethnicity - African American',
            filter: true,
            sort: true,
            hideInTable: true,
        },
        {
            name: 'devTags.CCS_RaceEthn_AmericanIndianAlaskaNative',
            label: 'Race/Ethnicity - American Indian or Alaska Native',
            filter: true,
            sort: true,
            hideInTable: true,
        },
        {
            name: 'devTags.CCS_RaceEthn_Asian',
            label: 'Race/Ethnicity - Asian',
            filter: true,
            sort: true,
            hideInTable: true,
        },
        {
            name: 'devTags.CCS_RaceEthn_HispanicLatinx',
            label: 'Race/Ethnicity - Hispanic or Latinx',
            filter: true,
            sort: true,
            hideInTable: true,
        },
        {
            name: 'devTags.CCS_RaceEthn_White',
            label: 'Race/Ethnicity - White',
            filter: true,
            sort: true,
            hideInTable: true,
        },
        {
            name: 'devTags.CCS_RaceEthn_NotListed',
            label: 'Race/Ethnicity - Not Listed',
            filter: true,
            sort: true,
            hideInTable: true,
        },
        {
            name: 'devTags.CCS_Income',
            label: 'Income',
            filter: true,
            sort: true,
            hideInTable: true,
        },
        {
            name: 'devTags.CCS_EduLevel',
            label: 'Education Level',
            filter: true,
            sort: true,
            hideInTable: true,
        },
        {
            name: 'devTags.CCS_ChildWelfare',
            label: 'Child Welfare',
            filter: true,
            sort: true,
            hideInTable: true,
        },
        {
            name: 'devTags.CCS_CriminalJustice',
            label: 'Criminal Justice',
            filter: true,
            sort: true,
            hideInTable: true,
        },
        {
            name: 'devTags.CCS_Medicaid',
            label: 'Medicaid',
            filter: true,
            sort: true,
            hideInTable: true,
        },
        {
            name: 'devTags.CCS_MHTreatment',
            label: 'Mental Health Treatment',
            filter: true,
            sort: true,
            hideInTable: true,
        },
        {
            name: 'devTags.CCS_TANF',
            label: 'TANF',
            filter: true,
            sort: true,
            hideInTable: true,
        },
        {
            name: 'devTags.CCS_WicSNAP',
            label: 'Wic/SNAP',
            filter: true,
            sort: true,
            hideInTable: true,
        },
        {
            name: 'devTags.CCS_None',
            label: 'No Services',
            filter: true,
            sort: true,
            hideInTable: true,
        },
    ];
}

function CommunitySurveys() {
    const [sites, setSites] = useState();
    const [site, setSite] = useState();
    const tableRef = useRef();

    useEffect(() => {
        getSites();
    }, []);

    async function getSites() {
        await apollo
            .query({
                query: sitesQuery,
                fetchPolicy: 'network-only',
            })
            .then(
                ({
                    data: {
                        groups: { siteList },
                    },
                }) => {
                    setSites(siteList);
                    setSite(siteList[0]);
                }
            );
    }

    if (!site) {
        return <Loader loading />;
    }

    return (
        sites && (
            <div style={{ margin: '1em' }}>
                <h1>
                    <strong>Community Surveys</strong>
                </h1>
                {sites.length > 1 && (
                    <div style={{ marginTop: '1rem' }}>
                        <strong>Select Coalition/PAC County:</strong>
                        <select
                            className="form-control"
                            style={{ width: '30rem' }}
                            onChange={async e => {
                                setSite(
                                    sites.find(s => s.group.groupId === Number(e.target.value))
                                );
                            }}
                        >
                            {sites.map(option => (
                                <option key={option.group.groupId} value={option.group.groupId}>
                                    {option.group.label}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button
                        type="button"
                        className="btn btn-default"
                        style={{ marginRight: '1em' }}
                        onClick={() =>
                            setClipboardText(
                                Boilerplate.url(
                                    `/coalitions/community/${site.childRoles.metricspawn.pawn.respondentHash}`
                                )
                            )
                        }
                    >
                        <IconClipboard />
                    </button>
                    <Modal
                        trigger={
                            <button type="button" className="btn btn-primary">
                                <IconAdd spaceRight />
                                <span>New Survey</span>
                            </button>
                        }
                        size="80%"
                        bodyStyle={{
                            overflow: 'scroll',
                        }}
                        afterClose={() => tableRef.current.loadData()}
                    >
                        <CommunitySurveyPlayer
                            flowProps={{
                                variables: {
                                    coalition_pac_county_hash:
                                        site.childRoles.metricspawn.pawn.respondentHash,
                                },
                            }}
                        />
                    </Modal>
                </div>
                <Table
                    ref={tableRef}
                    rowKey="assignmentProgressId"
                    query={responsesQuery}
                    graphqlVariables={{
                        deploymentHandle: DEPLOYMENT_HANDLE,
                        siteHash: site.childRoles.metricspawn.pawn.respondentHash,
                    }}
                    columns={getColumns()}
                />
            </div>
        )
    );
}

export default CommunitySurveys;
