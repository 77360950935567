import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';

import axios from 'cccisd-axios';
import { client as apollo } from 'cccisd-apollo';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';

import deploymentQuery from './deployment.graphql';
import { DEPLOYMENT_HANDLE } from './index';

const Boilerplate = window.cccisd.boilerplate;

function CommunitySurveyPlayer({ flowProps }) {
    const [anonPawn, setAnonPawn] = useState();

    useEffect(() => {
        getAnonPawn();
    }, []);

    async function getAnonPawn() {
        const deploymentQueryResponse = await apollo.query({
            query: deploymentQuery,
            fetchPolicy: 'network-only',
            variables: {
                deploymentHandle: DEPLOYMENT_HANDLE,
            },
        });

        try {
            let response = await axios.post(
                Boilerplate.route('api.assignmentDeployment.addDeploymentAnon', {
                    deploymentId: deploymentQueryResponse.data.flows.deployment.deploymentId,
                })
            );

            const responsePawn = _get(response, 'data.data');
            if (responsePawn && responsePawn.id) {
                // const updatedUri = `${match.url}/${responsePawn.respondent_hash}`;
                // history.push(updatedUri);

                setAnonPawn({
                    pawnId: responsePawn.id,
                    pawnHash: responsePawn.random_hash,
                    // respondentHash: responsePawn.respondent_hash,
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    if (!anonPawn) {
        return <Loader loading />;
    }

    return (
        <DeploymentPlayer
            deploymentHandle={DEPLOYMENT_HANDLE}
            pawnId={anonPawn.pawnId}
            pawnHash={anonPawn.pawnHash}
            flowProps={{
                hidePlayButton: true,
                ...flowProps,
            }}
        />
    );
}

export default CommunitySurveyPlayer;
